<template>
  <div class="bk-page">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form :label-width="70" @submit.native.prevent class="bk-page-search" inline>
          <FormItem label="状态">
            <Select clearable v-model="searchData1.Status">
              <Option
                :key="item.key"
                :value="item.key"
                v-for="item in $designConst.equipment.StatusLst"
              >{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="实验室">
            <Select clearable v-model="searchData1.LabId">
              <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{item.Name}}</Option>
            </Select>
          </FormItem>
          <FormItem label="名称">
            <Input clearable v-model="searchData1.Name" />
          </FormItem>
          <FormItem label="仪器编号">
            <Input clearable v-model="searchData1.Code" />
          </FormItem>
          <FormItem class="btns" style="width: 220px">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="toAdd" ghost type="primary" v-if="$able.map.EquLstAdd">
              <Icon type="md-add" />新增
            </Button>
          </FormItem>
        </Form>
        <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </div>
    </div>
    <edit @on-add="onChangePage(1)" @on-upd="reqTable" ref="edit" />
  </div>
</template>
<script>
import edit from "./edit";
import SelectLabId from "@/components/select-labid.js";
export default {
  components: { edit },
  mixins: [SelectLabId],
  data() {
    return {
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        {
          title: "仪器状态",
          key: "Status",
          width: 70,
          render: (h, params) => {
            return h("Badge", {
              props: {
                status:
                  this.$designConst.equipment.StatusBadge[params.row.Status],
                text: this.$designConst.equipment.Status[params.row.Status],
              },
            });
          },
        },
        { title: "实验室", key: "LabName", width: 70 },
        { title: "仪器名称", key: "Name", minWidth: 100 },
        { title: "规格型号", key: "ModeNo", minWidth: 100 },
        { title: "精度", key: "Precision", width: 70 },
        { title: "仪器编号", key: "Code", width: 100 },
        {
          title: "计量证书编号",
          key: "MetrologyAccreditationCode",
          width: 170,
        },
        {
          title: "计量有效期",
          render: (h, params) => {
            return [
              h(
                "span",
                params.row.MetrologyAccreditationDate
                  ? params.row.MetrologyAccreditationDate.substring(0, 10)
                  : ""
              ),
              h(
                "span",
                { style: { margin: "0 5px" } },
                params.row.MetrologyAccreditationDate &&
                  params.row.MetrologyAccreditationDate2
                  ? "~"
                  : "--"
              ),
              h(
                "span",
                params.row.MetrologyAccreditationDate2
                  ? params.row.MetrologyAccreditationDate2.substring(0, 10)
                  : ""
              ),
            ];
          },
          width: 190,
        },
        {
          title: "计量状态",
          key: "MaStatus",
          width: 70,
          render: (h, params) => {
            return h(
              "span",
              {
                style:
                  params.row.MaStatus == "请计量"
                    ? {
                        color: "#ed4014",
                      }
                    : {},
              },
              params.row.MaStatus
            );
          },
        },
        { title: "管理员", key: "ManagerName", width: 70 },
        {
          title: "操作",
          width: 80,
          align: "right",
          render: (h, params) => {
            let a = [];
            a.push(
              h("Icon", {
                props: {
                  type: this.$able.map.EquLstUpd ? "md-create" : "md-open",
                  size: 18,
                },
                attrs: { title: this.$able.map.EquLstUpd ? "编辑" : "详情" },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.$refs.edit.open(params.row.Id);
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.EquLstDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    console.info("激活：", this.$tab.conf[this.$route.name].label);
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$refs.edit.flag) {
        this.$refs.edit.close();
      }
      this.reqLab();
      this.initSearch();
    },
    initSearch() {
      this.searchData1 = {};
      this.pageIndex = 1;
      this.onSearch();
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    reqTable() {
      if (this.$able.map.EquLstQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/BtmsEquipment/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // ==========
    toAdd() {
      this.$refs.edit.open();
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsEquipment/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
