<template>
  <div id="equipmentEdit">
    <Drawer
      :mask-closable="false"
      :title="id ? '详情' : '新增'"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer equipment-edit-drawer"
      v-model="flag"
      width="700"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form
          :label-width="140"
          :model="formd"
          :rules="rules"
          @submit.native.prevent
          inline
          ref="form"
        >
          <FormItem label="状态：" prop="Status" required>
            <Select v-if="ABLE" v-model="formd.Status">
              <Option
                :key="item.key"
                :value="item.key"
                v-for="item in $designConst.equipment.StatusLst"
              >{{ item.name }}</Option>
            </Select>
            <div v-else>{{$designConst.equipment.Status[formd.Status]}}</div>
          </FormItem>
          <FormItem label="实验室：" prop="LabId" required>
            <Select v-if="ABLE" v-model="formd.LabId">
              <span slot="prefix">
                <Icon class="bk-load" type="ios-loading" v-if="LabLoading"></Icon>
              </span>
              <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{item.Name}}</Option>
            </Select>
            <div v-else>{{LabMap[formd.LabId]}}</div>
          </FormItem>
          <FormItem label="仪器名称：" prop="Name" required>
            <Input v-if="ABLE" v-model="formd.Name" />
            <div v-else>{{formd.Name}}</div>
          </FormItem>
          <FormItem label="仪器编号：" prop="Code" required>
            <Input v-if="ABLE" v-model="formd.Code" />
            <div v-else>{{formd.Code}}</div>
          </FormItem>
          <FormItem label="规格型号：" prop="ModeNo" required>
            <Input v-if="ABLE" v-model="formd.ModeNo" />
            <div v-else>{{formd.ModeNo}}</div>
          </FormItem>
          <FormItem label="计量证书编号：">
            <Input v-if="ABLE" v-model="formd.MetrologyAccreditationCode" />
            <div v-else>{{formd.MetrologyAccreditationCode}}</div>
          </FormItem>
          <br />
          <FormItem label="计量有效期(开始)">
            <DatePicker v-if="ABLE" v-model="formd.MetrologyAccreditationDate" />
            <div v-else>{{formd.MetrologyAccreditationDate}}</div>
          </FormItem>
          <FormItem label="计量有效期(结束)">
            <DatePicker v-if="ABLE" v-model="formd.MetrologyAccreditationDate2" />
            <div v-else>{{formd.MetrologyAccreditationDate2}}</div>
          </FormItem>
          <FormItem label="部门：">
            <Select @on-change="onDept" clearable label-in-value v-if="ABLE" v-model="formd.DeptId">
              <Option :key="item.Id" :value="item.Id" v-for="item in deptLst">{{ item.DeptName }}</Option>
            </Select>
            <div v-else>{{formd.DeptName}}</div>
          </FormItem>
          <FormItem label="管理员：">
            <Input :value="formd.ManagerName" @on-clear="unPickUser" clearable readonly v-if="ABLE">
              <Button @click="toPickUser" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-else>{{formd.ManagerName}}</div>
          </FormItem>
          <FormItem label="固定资产编号：">
            <Input v-if="ABLE" v-model="formd.AssetsNo" />
            <div v-else>{{formd.AssetsNo}}</div>
          </FormItem>
          <FormItem label="仪器精度：">
            <Input v-if="ABLE" v-model="formd.Precision" />
            <div v-else>{{formd.Precision}}</div>
          </FormItem>
          <FormItem label="采购员：">
            <Input v-if="ABLE" v-model="formd.Purchaser" />
            <div v-else>{{formd.Purchaser}}</div>
          </FormItem>
          <FormItem label="联系人：">
            <Input v-if="ABLE" v-model="formd.Contact" />
            <div v-else>{{formd.Contact}}</div>
          </FormItem>
          <FormItem label="联系电话：">
            <Input v-if="ABLE" v-model="formd.ContactTel" />
            <div v-else>{{formd.ContactTel}}</div>
          </FormItem>
          <FormItem label="联系邮箱：">
            <Input v-if="ABLE" v-model="formd.ContactEmaill" />
            <div v-else>{{formd.ContactEmaill}}</div>
          </FormItem>
          <FormItem class="long" label="备注：">
            <Input type="textarea" v-if="ABLE" v-model="formd.Remark" />
            <div v-else>{{formd.Remark}}</div>
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="ABLE">
        <Button :loading="posting" @click="onOk" type="primary">保存</Button>
      </div>
    </Drawer>
    <pick-user @on-ok="onPickUser" ref="PickUser" type="1" />
  </div>
</template>
<script>
import PickUser from "@/components/PickUser.vue";
import SelectLabId from "@/components/select-labid.js";
export default {
  components: { PickUser },
  mixins: [SelectLabId],
  data() {
    return {
      ABLE: false,
      flag: false,
      id: 0,
      formd: {},
      rules: {
        Status: [{ required: true, type: "number", message: " " }],
        LabId: [{ required: true, type: "number", message: " " }],
        Name: [{ required: true, message: " " }],
        Code: [{ required: true, message: " " }],
        ModeNo: [{ required: true, message: " " }],
      },
      loading: false,
      failed: false,
      posting: false,
      deptLst: [],
      // ——————————
      LabLst: [],
      labLoading: false,
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      if (id) {
        this.ABLE = this.$able.map.EquLstUpd;
        this.id = id;
        this.req();
      } else {
        this.ABLE = this.$able.map.EquLstAdd;
      }
      this.reqLab();
      this.reqDept();
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsEquipment/GetByID/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
      this.id = 0;
    },
    onOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let api =
        this.id > 0 ? "/Api/BtmsEquipment/Update" : "/Api/BtmsEquipment/Add";
      let params = this.$util.copy(this.formd);
      if (
        this.formd.MetrologyAccreditationDate != null &&
        typeof this.formd.MetrologyAccreditationDate == "object"
      ) {
        params.MetrologyAccreditationDate =
          this.formd.MetrologyAccreditationDate.format("yyyy-MM-dd");
      }
      if (
        this.formd.MetrologyAccreditationDate2 != null &&
        typeof this.formd.MetrologyAccreditationDate2 == "object"
      ) {
        params.MetrologyAccreditationDate2 =
          this.formd.MetrologyAccreditationDate2.format("yyyy-MM-dd");
      }
      this.$axios
        .post(api, params)
        .then((res) => {
          if (res.result) {
            this.$Message.success(this.id > 0 ? "编辑成功" : "新增成功");
            this.$emit(this.id > 0 ? "on-upd" : "on-add");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // ==========
    reqDept() {
      this.$axios.get("/Api/OrgDept/QueryAll").then((res) => {
        if (res.result) {
          this.deptLst = res.data;
        }
      });
    },
    onDept(obj) {
      if (obj) {
        this.$set(this.formd, "DeptName", obj.label);
      } else {
        this.$set(this.formd, "DeptName", "");
      }
    },
    // 选择人员
    toPickUser() {
      let def = [];
      if (this.formd.ManagerId) {
        def = [
          {
            Id: this.formd.ManagerId,
            UserName: this.formd.ManagerName,
          },
        ];
      }
      this.$refs.PickUser.open(def);
    },
    onPickUser(lst) {
      if (lst[0]) {
        this.formd.ManagerId = lst[0].Id;
        this.$set(this.formd, "ManagerName", lst[0].UserName);
      } else {
        this.formd.ManagerId = "";
        this.$set(this.formd, "ManagerName", "");
      }
    },
    unPickUser() {
      this.formd.ManagerId = "";
      this.$set(this.formd, "ManagerName", "");
    },
  },
};
</script>
<style lang="less">
.equipment-edit-drawer {
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>